import React from 'react'
import { T } from '../../typo'
import { s, globals, colors, alpha } from '../../style'
import { ButtonText } from '../../buttons'
import Img from 'gatsby-image'

const Feature = ({ theme, feature, index, language }) => {
  // console.log(feature)
  const {
    slug_section,
    buttonSmall,
    description,
    image,
    props,
    titleObject,
  } = feature
  return (
    <div
      id={slug_section}
      css={{
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        [s.sm_down]: { paddingTop: '6rem' },
        [s.md]: { paddingTop: '2rem' },
      }}>
      <div css={sSpacing} />

      <section
        css={[sContainer, sTheme(theme), index % 2 !== 0 && sContainer.odd]}>
        {image && (
          <Img
            style={sImage}
            fluid={image.childImageSharp.fluid}
            alt={image.name}
          />
        )}
        <div css={sText}>
          <T
            d={24}
            m={24}
            mb={1.5}
            bold
            condensed
            center
            variant={titleObject.variant}
            extraCss={{ color: colors[theme].main }}>
            {titleObject.text}
          </T>
          <T o={0.6} d={14} m={12} mb={2.5}>
            {description}
          </T>
          <div css={sProps}>
            {props.map((prop, id) => (
              <div key={id} css={sProp}>
                <div className="bullet" />
                <span>{prop.title}</span>
              </div>
            ))}
          </div>
        </div>
        {buttonSmall?.text && (
          <div css={[sButton, { color: colors[theme].main }]}>
            <ButtonText language={language} theme={theme} button={buttonSmall}>
              <span>{buttonSmall.text_before}</span>
              <span css={{ fontSize: '0.875rem' }}>{buttonSmall.text}</span>
            </ButtonText>
          </div>
        )}
      </section>
    </div>
  )
}

const sSpacing = {
  width: '100%',
  marginTop: 64,
  display: 'flex',
}

const sContainer = {
  flexShrink: 0,
  position: 'relative',

  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignSelf: 'stretch',
  [s.sm_down]: {
    width: `calc(100vw - ${globals.spacing.outside.mobile * 2}px) `,
    margin: globals.spacing.outside.mobile,
    boxShadow: globals.shadows.sections.mobile,
    padding: globals.spacing.inside.mobile,
    borderRadius: globals.roundness.sections.mobile,
    paddingTop: 96,
    height: 'calc(100% - 128px)',
  },
  [s.sm]: { width: `calc(50vw - ${globals.spacing.outside.mobile * 2}px) ` },
  [s.md]: {
    height: 'calc(100% - 64px)',
    width: 360,
    padding: globals.spacing.inside.desktop / 2,
    paddingTop: 96,
    boxShadow: globals.shadows.sections.desktop,
    borderRadius: globals.roundness.sections.desktop,
  },
  odd: {
    [s.md]: { margin: '0 32px' },
  },
}

const sTheme = (theme) => ({
  backgroundColor: colors[theme].container.concat(alpha[30]),
  '.bullet': {
    backgroundColor: colors[theme].main.concat(alpha[30]),
    width: 8,
    height: 8,
    display: 'flex',
    flexShrink: 0,
    borderRadius: 4,
  },
})

const sText = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: 'column',
}

const sProps = {
  display: 'flex',
  flexDirection: 'column',
}

const sProp = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  span: {
    marginLeft: '1rem',
  },
  marginBottom: '1rem',
}

const sButton = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  [s.sm_down]: { paddingTop: globals.spacing.inside.mobile },
  [s.md]: { paddingTop: globals.spacing.inside.desktop / 4 },
  fontSize: '0.875rem',
}

const sImage = {
  width: 128,
  height: 128,
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%,-50%)',
  borderRadius: 64,
  boxShadow: `0px 8px 32px ${colors.black.concat(alpha[16])}`,
}

export default Feature
